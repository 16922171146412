@use '../../styles/yr' as *;

.static-search-form {
  display: flex;
}

.static-search-form__input-container {
  flex: 1;
  margin-right: size(2);
  position: relative;
}

.static-search-form__input {
  background-color: var(--color-background-base);
  border: 1px solid var(--color-stroke-subtle);
  height: size(5);
  padding: size(1) size(1) size(1) size(5.5);
  width: 100%;
  border-radius: size(1);
  -webkit-appearance: none;

  // Prevent auto zoom on focus (iOS)
  // See https://stackoverflow.com/q/2989263
  font-size: 16px;
}

.static-search-form__icon {
  left: size(1.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  // When clicking the icon the search input itself should receive the click/focus
  pointer-events: none;
}
